<template>
  <b-row v-if="integration != false && params != false">
    <b-col md="8" offset-md="2">
      <div class="p-5">
        <p class="h5 text-dark"></p>
        <b-card>
          <b-card-title class="text-dark">
            Intégration de solutions sur votre site ou autre support digital :
          </b-card-title>
          <b-card-text>
            Pautions vous propose plusieurs types d’éléments que vous pouvez intégrer sur vos supports.<br>
            Retrouvez les ci-dessous, nous vous expliquons comment les intégrer.
          </b-card-text>
          <small>
            Si vous avez des questions techniques, n’hésitez pas à contacter notre support client prévu à cet effet à l’adresse mail suivante : support.client@pautions.fr.
          </small>
          <b-row class="mt-3">
            <b-col md="3" v-for="(integration, i) in params.IntegrationType" :key="i" class="p-4 text-center border rounded ms-3" @click="type = integration.id" :class="integration.id === type ? 'border-success bg-success' : 'border-primary bg-primary'">
              <b-form-radio v-model="type" name="type" :value="integration.id" class="text-white">{{integration.titre}}</b-form-radio>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="12" v-if="type === 2">
              <b-card-text>
                Ci-dessous voici votre lien de partenaire. Vous pouvez l’utiliser de plusieurs manières, sous forme de bouton, en lien hypertexte ou via des campagnes mailing ou sms. Il dirigera vos utilisateurs vers notre parcours client.
              </b-card-text>
              <b-form-group label="Page d'accueil:" class="mt-2">
                <b-input-group class="mt-3">
                  <b-form-input ref="inter-type-2-1" :value="`https://pautions.fr?key=${integration.api_key}&type=2`" disabled></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" :href="`https://pautions.fr?key=${integration.api_key}&type=2`" target="_blank"><i class="fas fa-eye"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Page Attestation Provisoire:" class="mt-2">
                <b-input-group class="mt-3">
                  <b-form-input ref="inter-type-2-1" :value="`https://pautions.fr/attestation-provisoire?key=${integration.api_key}&type=2`" disabled></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" :href="`https://pautions.fr/attestation-provisoire?key=${integration.api_key}&type=2`" target="_blank"><i class="fas fa-eye"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Parcours client:" class="mt-2">
                <b-input-group class="mt-3">
                  <b-form-input ref="inter-type-2" :value="type2" disabled></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" :href="`${$store.api.urlAPP}/#/zero-depot?key=${integration.api_key}&type=2`" target="_blank"><i class="fas fa-eye"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Clé API:" class="mt-2">
                <b-form-input class="mt-3" :value="integration.api_key" disabled></b-form-input>                
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="type === 3">
              <b-card-text>
                Pensé pour être intégré sur des pages d’annonces immobilières, ce widget se présente sous la forme d’un bloc qui reprend vos données du loyer et du type de location (vide ou meublée) pour ensuite afficher automatiquement le coût de l’assurance Zéro Dépôt ainsi que le montant du dépôt de garantie économisé.
              </b-card-text>
              <br>
              <span>Pour son bon fonctionnement, il faut renseigné/remplacer les valeurs des 4 attributs suivants : <strong>data-loyer</strong> / <strong>data-meuble</strong> / <strong>data-loyer-charges</strong> / <strong>data-depot-coef</strong></span><br>
              <div class="mt-3">
                <span><strong>data-loyer = Interger</strong></span><br>
                <small>> data-loyer étant le montant du loyer.</small>
              </div>
              <div class="mt-3">
                <span><strong>data-meuble = String</strong></span><br>
                <small>> data-meuble étant pour savoir si la location est une location vide ou meublée.</small><br/>
                <ul>
                  <li>La valeur attendu est "oui" si meublée</li>
                  <li>La valeur attendu est "non" si vide</li>
                </ul>
              </div>
              <div class="mt-3">
                <span><strong>data-loyer-charges = Interger</strong></span><br>
                <small>> data-loyer-charges étant le montant des charges associées au loyer.<br>
                  <ul>
                    <li>Si le loyer indiqué sur la page du logement est charges comprises, renseigné cette data et le montant des charges viendra se soustraire au dépôt de garantie économisé.</li>
                    <li>Si le loyer indiqué sur la page du logement est charges non comprises, vous pouvez laisser cette data non renseignée.</li>
                  </ul>
                </small>
              </div>
              <div class="mt-3">
                <span><strong>data-depot-coef = Interger</strong></span><br>
                <small>> data-depot-coef passe outre l'information de data-meuble si elle est renseigné.<br>
                  <ul>
                    <li>Si vous indiquez 1, le dépôt de garantie affiché sera de 1 mois de loyer</li>
                    <li>Si vous indiquez 2, le dépôt de garantie affiché sera de 2 mois de loyer</li>
                    <li>Si vous laissez vide, le dépôt de garantie prendra la data-meuble</li>
                  </ul>
                </small>
              </div>
              <!-- <span><strong>data-loyer = Interger.</strong> <small>(data-loyer étant le montant du loyer charge comprise)</small></span><br>
              <span><strong>data-meuble = String, oui ou non.</strong> <small>(data-meuble étant pour savoir si la location est une location vide ou meublée)</small></span><br> -->

              <b-form-group label="Code:" class="mt-2">
                <b-form-textarea  :value="type3" disabled rows="5"></b-form-textarea>
              </b-form-group>
              <div class="embed-responsive embed-responsive-16by9 mt-3 text-center">
                <iframe width="100" :srcdoc="type3" class="embed-responsive-item" style="width: 75%; height: 400px;"></iframe>
              </div>
            </b-col>
            <b-col md="12" v-if="type === 4">
              <b-card-text>Le widget Calculateur peut être intégré ou bon vous semble puisqu’il permet à l’utilisateur de rentrer le montant d’un loyer ainsi que le type de location pour connaître directement le coût de l’assurance Zéro Dépôt et le montant du dépôt de garantie qu’il n’aura pas à dépenser. Il peut ensuite s’inscrire si il le souhaite.
              <span>Aucune configuration particulière n’est requise pour ce bloc, c’est l’utilisateur qui rentre les données.</span>
              </b-card-text>
              <b-form-group label="Code:" class="mt-2">
                <b-form-textarea  :value="type4" disabled rows="3"></b-form-textarea>
              </b-form-group>
              <div class="embed-responsive embed-responsive-16by9 mt-3 text-center">
                <iframe width="100" :srcdoc="type4" class="embed-responsive-item" style="width: 75%; height: 500px;"></iframe>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "PartenaireIntegration",
  components: {
  },
  data(){
    return {
      params: false,
      integration: false,
      type: null
    }
  },
  methods: {
  },
  computed:{
    type2(){
      return `${this.$store.api.urlAPP}/#/zero-depot?key=${this.integration.api_key}&type=2`;
    },
    type3(){
      var txt = `<link href="${this.$store.api.urlAPP}/widget/js/main.js" rel="preload" as="script">`+"\n";
      txt += `<div id="pautions" data-key="${this.integration.api_key}" data-type="annonce" data-loyer="2500" data-meuble="non" data-loyer-charges="50" data-depot-coef="2">`      
      /* eslint-disable */
      txt += `<script src="${this.$store.api.urlAPP}/widget/js/main.js"><\/script>`;
      return txt;
    },
    type4(){
      var txt = `<link href="${this.$store.api.urlAPP}/widget/js/main.js" rel="preload" as="script">`+"\n";
      txt += `<div id="pautions" data-key="${this.integration.api_key}" data-type="calculateur"></div>`+"\n";
      /* eslint-disable */
      txt += `<script src="${this.$store.api.urlAPP}/widget/js/main.js"><\/script>`;
      return txt;
    }
  },
  beforeMount() {
    this.$store.api.ajax('/partenaire/params', null, res => {
      if(res.status === true){
        res.data.IntegrationType = res.data.IntegrationType.filter(i => i.id != 1);
        this.params = res.data;
      }
    })
  },
  mounted() {
    this.$store.api.ajax('/partenaire/integration', null ,res => {
      if(res.status === true){
        this.integration = res.data
      }
    })
  }

}
</script>
<style scoped>
</style>
